:root {
	--font-color: #61dafb;
	--link-color: #009cf7;
	--bg-color: #282c34;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--bg-color);
	color: var(--font-color);
	display: flex;
	flex: 1 1;
	flex-direction: column;
	width: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
	cursor: pointer;
}

a:-webkit-any-link {
	text-decoration: none;
	color: var(--link-color);
}

button:disabled {
	cursor: not-allowed;
}