.iCheck {
	width: 62px !important;
	height: 32px;
	border-radius: 25px;
	position: relative;
	background: #ADADA5 !important;
	transition: all .2s;
	appearance: none;
	margin-left: auto;
}

.iCheck::after {
	content: '';
	position: absolute;
	top: 2px;
	left: 2px;
	width: 28px;
	height: 28px;
	border-radius: 50%;
	background: white;
	box-shadow: 0 1px 2px rgba(44, 44, 44, .2);
	transition: all .2s cubic-bezier(.5, .1, .75, 1.35);
}

.iCheck:checked::after {
	transform: translatex(30px)
}

.iCheck:checked {
	background: #8DD636 !important;
}

.imgDetails {
	position: relative;
}

.imgDetails:hover {
	opacity: 0.5;
}

.imgDetails i {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0;
}

.imgDetails:hover i {
	opacity: 1;
}

.du46figure {
	margin: 1rem 0;
	border: 5px solid;
}

.du46figure div {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 1rem;
}

.du46figure h3, .du46figure p {
	margin: 0;
	text-align: center;
}

.du46figure img {
	width: 400px;
	height: 400px;
	object-fit: cover;
}

.voltOne {
	display: flex;
	flex-flow: column nowrap;
	margin: 0 10px 10px;
}

.voltOne b{
	width: 100px;
}

.voltOne p{
	width: 100px;
}

.voltOne p:last-child{
	text-align: end;
}