.loginForm {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	text-align: center;
	header {
		h2 {
			font-size: 20px;
			padding: 15px;
			margin: 0;
		}
	}
	main {
		display: flex;
		flex-direction: column;
		height: 100%;
		width: calc(100% - 2rem);
		padding: 1rem;
		margin-bottom: 10px;
		&.notMB {
			margin-bottom: 0
		}
		label {
			display: flex;
			align-items: center;
			margin-bottom: 0.5rem;
			gap: 1rem;
			div {
				display: flex;
				flex-direction: column;
				width: 100%;
				span {
					display: flex;
					font-size: 1.2rem;
					abs {
						color: red;
						margin-left: auto;
					}
				}
				input {
					background-color: transparent;
					border: 0;
					border-bottom: 1px solid var(--font-color);
					padding: 5px 0 10px;
					color: var(--font-color);
					font-size: 17px;
					line-height: 20px;
					&::placeholder {
						color: var(--font-color);
						opacity: .5;
					}
					&:focus-visible {
						outline: 0;
						border-bottom: 1px solid var(--link-color);
					}
				}
			}
			&+div {
				margin-top: 16px;
			}
		}
	}
}


.last {
	margin: auto 0 0;
}

button.button {
	background-color: var(--font-color);
	color: var(--bg-color);
	width: 100%;
	padding: 1rem;
	border-radius: 10px;
	font-size: 1rem;
	font-weight: bold;
	border: 0;
	cursor: pointer;
	&:active {
		opacity: .8;
	}
	&:disabled {
		opacity: .75;
	}
	&.margin10 {
		margin: 10px 0 0;
	}
}
.mTop05 {
	margin-top: .5rem;
}