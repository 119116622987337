.cutting4flex {
	display: grid;
	grid-template-columns: 49% 49%;
	align-items: end;
	gap: 2%;
	a {
		button {
			@media screen and (max-width: 680px) {
				height: 64px;
			}
			@media screen and (max-width: 450px) {
				height: 86px;
			}
		}
	}
}

button.kadrButtonExit {
	@media screen and (max-height: 720px) {
		margin-top: calc(2% + .5rem);
	}
}

button.notKadrButtonExit {
	@media screen and (max-height: 570px) {
		margin-top: calc(2% + .5rem);
	}
}