.photosFigure {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	grid-gap: 1rem;
	margin: 10px 0;
	img {
		width: 100%;
	}
}

.depTasksStyledGroup {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	margin-top: 1rem;
}

.depTasksStyledArticle {
	width: 100%;
	flex-direction: column;
	border: 3px solid;
	padding: 1rem;
}

.depsBtns {
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
}

.depsBtn {
	grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
	border-radius: 20px;
	height: 50px;
	display: flex;
	text-align: center;
}
.depsBtn b {
	margin: auto;
	font-size: 20px;
	color: #000;
	-webkit-text-stroke: .5px #fff;
}

.depsBtn.green, .green {
	background-color: #28c737
}

.depsBtn.red, .red {
	background-color: red
}

.depsBtn.yellow, .yellow {
	background-color: yellow
}

.depsBtn.white {
	background-color: var(--link-color)
}

.chvoltOne {
	display: grid;
	grid-template-columns: 100px auto 100px;
	grid-template-rows: 4rem;
	align-items: center;
	border: 3px solid;
	margin: 10px 0;
	padding: 0 10px;
}

.chvoltOne p {
	font-size: 20px;
	color: #000;
	font-weight: 700;
}