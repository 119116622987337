select.chooseDepartment {
	appearance: none;
	border: 0;
	outline: 0;
	font: inherit;
	width: 100%;
	height: 3em;
	padding: 0 4em 0 1em;
	color: white;
	border-radius: 0.25em;
	box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
	background: url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg)
		no-repeat right 0.8em center / 1.4em,
	  linear-gradient(to left, rgba(255, 255, 255, 0.3) 3em, rgba(255, 255, 255, 0.2) 3em);
	cursor: pointer;
	option {
		color: #fff;
		background: rgb(83 86 92);
		border: 0;
	}
	&:focus {
		outline: none;
	}
	&::-ms-expand {
		display: none;
	}
}
label.chooseDepartmentUser {
	width: 140px;
	height: 3em;
	background: rgb(83 86 92);
	border-radius: 20px;
	text-align: center;
	justify-content: center !important;
	grid-template-columns: 140px !important;
	&.active {
		background: var(--font-color);
		color: var(--bg-color);
	}

	input[type="checkbox"]{
		display: none;
		&:checked {
			background: rgb(83 86 92);
			border-radius: 20px;
			text-align: center;
			justify-content: center !important;
		}
	}
}