form.formCreate {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	label {
		font-size: 1rem;
		height: 60px;
		display: grid;
		grid-template-columns: minmax(82px,140px) minmax(140px,1fr) min-content;
		align-items: center;
		input, select{
			flex-grow: 1;
			background-color: transparent;
			color: var(--font-color);
			font-size: 1rem;
			border: 0;
			outline: 0;
			margin-left: 15px;
			width: -webkit-fill-available;
			option{
				color: var(--font-color);
				background-color: var(--bg-color);
			}
		}
	}
}