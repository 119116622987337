footer.navFooter {
	position: fixed;
	bottom: 0px;
	width: 100%;
	height: 82px;
	z-index: 5;
	background-color: var(--bg-color);

	nav {
		display: flex;
		justify-content: space-around;
		align-items: center;
		max-width: 1140px;
		margin: 0 auto;
		a {
			padding: 8px;
			text-align: center;
			font-size: 13px;
			color: #61dafb;
			&.active {
				color: #fff !important;
			}
		};
	}
}