header.authHead {
	h2 {
		padding: 15px;
		font-size: 20px;
		margin: 0;
		flex: 1;
		text-align: center;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
	display: flex;
	align-items: center;
}
main.authMain {
	width: calc(100% - 2rem);
	height: calc(var(--page-height) - 57px);
	display: flex;
	flex-direction: column;
	padding: 0 1rem;
	&.p404 {
		height: calc(var(--page-height));
	}
	article {
		display: flex;
		i {
			margin-right: 1rem;
		}
		section {
			margin-bottom: 1rem;
		}
	}
}